import { Button } from 'ui/components/ButtonV2/Button';

interface KeepShoppingButtonProps {
  onClick: (event: React.SyntheticEvent) => void;
  children: React.ReactNode;
}

function KeepShoppingButton({
  onClick,
  children,
}: KeepShoppingButtonProps): JSX.Element {
  return (
    <Button
      className='border-inactive border '
      onClick={onClick}
      size='medium'
      variant='primary'
    >
      {children}
    </Button>
  );
}

export default KeepShoppingButton;
