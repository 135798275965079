import React, { useState } from 'react';
import {
  Elements,
  PaymentMethodMessagingElement,
  useStripe,
} from '@stripe/react-stripe-js';
import type {
  Currency,
  Language,
} from '@whoop/i18n/types/internationalization';
import type { StripePaymentMethodMessagingElementOptions } from '@stripe/stripe-js';
import type { CountryCode } from '@whoop/i18n/types/address/countries';
import SkeletonWrap from 'ui/components/Skeleton/Skeleton';
import clsx from 'clsx';

interface StripeBNPLElementProps {
  currency: Currency;
  language: Language;
  price: number;
  countryCode: CountryCode;
  // This right aligns and shows the BNPL options in colors
  cartVariant?: boolean;
}

function StripeBNPLElement({
  currency,
  language,
  price,
  countryCode,
  cartVariant = false,
}: StripeBNPLElementProps): React.ReactNode {
  const [isLoading, setIsLoading] = useState(true);
  const stripe = useStripe();

  return (
    // Have a height on this element so that it doesnt move the page down after it loads */}
    <div className='h-10'>
      {isLoading ? (
        <div className={clsx('h-full w-full', !cartVariant && 'pr-4')}>
          <SkeletonWrap className='h-full w-full' />
        </div>
      ) : null}

      {/* Can remove the <Elements/> surrounding PaymentMethodMessagingElement if we don't want to customize the styling */}
      <Elements
        options={{
          amount: 200, // amount will be reset in Payment Element. Here we provide the min charge amount to prevent error upon initialization
          appearance: {
            theme: cartVariant ? 'stripe' : 'flat',
            rules: {
              '.PaymentMethodMessaging': {
                textAlign: cartVariant ? 'right' : '',
              },
            },
            disableAnimations: true,
          },
          currency,
          fonts: [
            {
              family: 'ProximaNova-Regular',
              src: 'url(https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)',
            },
          ],
          loader: 'always',
          locale: language,
          mode: 'payment',
          paymentMethodCreation: 'manual',
        }}
        stripe={stripe}
      >
        <PaymentMethodMessagingElement
          // paymentMethodTypes can be optional - if you don't pass it in, stripe automatically shows the available BNPLs
          // but seems to only work in the US
          onReady={() => {
            setIsLoading(false);
          }}
          options={{
            amount: price,
            currency:
              currency.toUpperCase() as StripePaymentMethodMessagingElementOptions['currency'],
            // the country that the end-buyer is in
            countryCode:
              countryCode as StripePaymentMethodMessagingElementOptions['countryCode'],
            paymentMethodTypes: ['klarna', 'affirm'],
            // @ts-expect-error - this is a valid option but not in the types since this was just added by stripe and the package hasn't been updated to reflect that
            paymentMethodOrder: ['affirm', 'klarna'],
          }}
        />
      </Elements>
    </div>
  );
}

export default StripeBNPLElement;
