import { cva, type VariantProps } from 'class-variance-authority';
import { cltw } from '../../utils';

/**
 * @documentation https://cva.style/docs
 */
const skeletonVariants = cva('', {
  variants: {
    variant: {
      default: 'animate-pulse bg-black/10',
      secondary:
        'relative isolate overflow-hidden bg-[#EDF0F7] before:animate-[shimmer_2s_infinite] before:shadow-skeleton before:absolute before:h-[300%] before:w-[200%] before:bg-[300% 0] before:bg-skeleton-gradient',
    },
    defaultVariants: {
      variant: 'default',
    },
  },
});

interface SkeletonProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof skeletonVariants> {}

/**
 * @description Skeleton implementation from Shadcn
 * @see https://ui.shadcn.com/docs/components/skeleton
 */
function Skeleton({
  className,
  variant = 'default',
  ...props
}: SkeletonProps): JSX.Element {
  return (
    <div
      className={cltw(className, skeletonVariants({ variant }))}
      {...props}
    />
  );
}

export default Skeleton;
