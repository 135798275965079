export const getDeviceType = (): string => {
  const userAgent = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
    return 'tablet';
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      userAgent,
    )
  ) {
    return 'mobile';
  }
  return 'desktop';
};

export const isMobile = (): boolean => getDeviceType() === 'mobile';
export const isTablet = (): boolean => getDeviceType() === 'tablet';
export const isDesktop = (): boolean => getDeviceType() === 'desktop';

type OS = 'Windows' | 'MacOS' | 'UNIX' | 'Linux' | 'Unknown';
export const userDeviceOS = (): OS => {
  let Name = 'Unknown' as OS;
  if (navigator.userAgent.includes('Win')) Name = 'Windows';
  if (navigator.userAgent.includes('Mac')) Name = 'MacOS';
  if (navigator.userAgent.includes('X11')) Name = 'UNIX';
  if (navigator.userAgent.includes('Linux')) Name = 'Linux';
  return Name;
};
