import type {
  Currency,
  Language,
  Region,
} from '@whoop/i18n/types/internationalization';
import clsx from 'clsx';
import type { CartDisplayItem, CartProduct, PromoInfo } from '../../../types';
import { CartItem } from '../CartItem/CartItem';

export interface CartContentsProps
  extends React.HTMLAttributes<HTMLDivElement> {
  currency: Currency;
  isLocked: boolean;
  items: CartDisplayItem[];
  language: Language;
  onItemQuantityChange?: (item: CartProduct, newQuantity: number) => void;
  hideBorder?: boolean;
  promoInfo?: PromoInfo;
  discountValue?: number;
  region: Region;
}

export function CartContents({
  className,
  currency,
  isLocked,
  items,
  language,
  region,
  promoInfo,
  onItemQuantityChange,
  ...props
}: CartContentsProps): JSX.Element {
  return (
    <div className={clsx('w-full', className)} {...props}>
      {items.map((item) => (
        <CartItem
          alert={item.alert}
          currency={currency}
          isLocked={isLocked}
          item={item.item}
          key={item.item.item.id}
          language={language}
          onQuantityChange={(newQuantity) => {
            if (onItemQuantityChange) {
              onItemQuantityChange(item.item, newQuantity);
            }
          }}
          promoInfo={promoInfo}
          region={region}
          upsell={isLocked ? undefined : item.upsell}
        />
      ))}
    </div>
  );
}
