import parser from 'accept-language-parser';
import { SUPPORTED_COUNTRIES } from '../types/address';
import type { Language } from '../types/internationalization';
import { ALL_REGIONS, DEFAULT_LANGUAGE } from '../types/internationalization';
import type { LanguagePreference } from '../types/languages';
import {
  ADDITIONAL_LANGUAGES_BY_COUNTRY,
  ADDITIONAL_LANGUAGES_BY_REGION,
} from '../types/languages';

export const getLanguagesForCountry = (country: string): Language[] => {
  if (
    !SUPPORTED_COUNTRIES.some((countryObj) => countryObj.alpha2 === country)
  ) {
    return [];
  }

  const additionalLanguages = ADDITIONAL_LANGUAGES_BY_COUNTRY[country] ?? [];
  return [DEFAULT_LANGUAGE, ...additionalLanguages];
};

export const getLanguagesForRegion = (region: string): Language[] => {
  if (!ALL_REGIONS.includes(region)) return [];

  const additionalLanguages = ADDITIONAL_LANGUAGES_BY_REGION[region] ?? [];
  return [DEFAULT_LANGUAGE, ...additionalLanguages];
};

export const pickLanguage = (
  availableLanguages: Language[],
  acceptLanguageHeader: string,
): Language => {
  let allowedLanguages = availableLanguages;

  // Our Portuguese translations are geared towards European Portuguese,
  // so we should only serve Portuguese to members who have European Portuguese (pt-PT)
  // as their top Portuguese preference.
  const preferences: LanguagePreference[] = parser.parse(acceptLanguageHeader);
  const ptPreference = preferences.find((pref) => pref.code === 'pt');
  if (ptPreference?.region !== 'PT') {
    allowedLanguages = availableLanguages.filter((lang) => lang !== 'pt');
  }

  return (
    parser.pick(allowedLanguages, acceptLanguageHeader, {
      loose: true, // example: "fr-CA" counts as a preference for "fr"
    }) ?? DEFAULT_LANGUAGE
  );
};
