'use client';
import React, { useMemo } from 'react';
import { Modal, ModalContent } from 'ui/components/ModalV2/Modal';
import { Trans, useTranslation } from '@whoop/i18n/lang/client';
import type { MembershipProduct } from 'ui';
import { getMembership, TERMS_OF_USE_URL } from 'ui';
import { Button } from 'ui/components/ButtonV2/Button';
import { useShallow } from 'zustand/react/shallow';
import { useSiteStore } from '@/store/SiteStoreProvider';
import { useFormatter } from '@/hooks/formatter';

export interface LearnMoreModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onChooseMembership?: () => void;
  cartLocked?: boolean;
}
export default function LearnMoreModal({
  isOpen,
  setIsOpen,
  onChooseMembership,
  cartLocked = false,
}: LearnMoreModalProps): JSX.Element | null {
  const { formatDisplayPrice } = useFormatter();
  const { t } = useTranslation('learnMoreModal');

  const { requiredProducts, addToCart, returnShippingCost } = useSiteStore(
    useShallow((state) => ({
      requiredProducts: state.requiredProducts,
      addToCart: state.addToCart,
      returnShippingCost: state.returnShippingCost,
    })),
  );

  const { annualMembership, trialMembership, monthlyMembership } =
    useMemo(() => {
      const trialMembership = getMembership(
        'trial',
        requiredProducts.join_memberships,
      );
      const annualMembership = getMembership(
        'prepaid',
        requiredProducts.join_memberships,
        12,
      );
      const monthlyMembership = getMembership(
        'monthly',
        requiredProducts.join_memberships,
      );

      return { annualMembership, trialMembership, monthlyMembership };
    }, [requiredProducts]);

  if (!trialMembership || !annualMembership) return null;

  const handleSelection = (membership: MembershipProduct): void => {
    setIsOpen(false);
    if (!cartLocked) {
      addToCart(membership);
    }
    if (onChooseMembership) {
      onChooseMembership();
    }
  };

  const annualPrice = formatDisplayPrice(annualMembership.display_price);
  const monthlyPrice = formatDisplayPrice(
    monthlyMembership?.display_price ?? 0,
  );
  const returnShippingFormattedPrice = returnShippingCost
    ? formatDisplayPrice(returnShippingCost)
    : null;

  let renewalPolicy: string;
  if (monthlyMembership && returnShippingFormattedPrice) {
    renewalPolicy = 'renewalPolicyReturnShipping';
  } else if (monthlyMembership) {
    renewalPolicy = 'renewalPolicy';
  } else if (returnShippingFormattedPrice) {
    renewalPolicy = 'renewalPolicyReturnShippingWithoutMonthly';
  } else {
    renewalPolicy = 'renewalPolicyWithoutMonthly';
  }

  return (
    <Modal onOpenChange={setIsOpen} open={isOpen}>
      <ModalContent className='bg-learn-more-modal z-header overflow-y-scroll bg-cover bg-no-repeat md:max-h-[80vh] md:min-w-[600px]'>
        <div
          className='grid grid-cols-1 gap-5 py-5 pl-3 pr-3 text-center'
          data-testid='learn-more-modal'
        >
          <div className='pb-2'>
            <h2 className='text-2xl font-bold uppercase'>{t('title')}</h2>
            <h3 className='text-base font-bold uppercase'>{t('subtitle')}</h3>
          </div>
          <div>
            <p className='py-1'>{t('whatsIncluded')}</p>
            <p className='py-1'>
              {t(renewalPolicy, {
                annualPrice,
                monthlyPrice,
                returnShippingCost: returnShippingFormattedPrice,
              })}
            </p>
          </div>
          <div>
            <Trans
              components={[
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  className='text-blue-strain underline'
                  href={TERMS_OF_USE_URL}
                  key='tos-link'
                  rel='noopener'
                  target='_blank'
                />,
              ]}
              i18nKey='learnMoreModal:termsAndConditions'
            />
          </div>
          <div className='flex w-full justify-center'>
            <Button
              className='w-full sm:w-1/2'
              onClick={() => handleSelection(trialMembership)}
              size='small'
              variant='secondary'
            >
              {t('trialWhoop')}
            </Button>
          </div>
          {!cartLocked ? (
            <div className='flex w-full justify-center'>
              <Button
                className='w-full sm:w-1/2'
                onClick={() => handleSelection(annualMembership)}
                size='small'
                variant='primary'
              >
                {t('annual')}
              </Button>
            </div>
          ) : null}
        </div>
      </ModalContent>
    </Modal>
  );
}
