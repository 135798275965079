// These constants are used in production code and should not be changed for tests

export const FAMILY_MEMBERSHIP_SIZE_2_SKU = '2-Person-Family-Membership';
export const FAMILY_MEMBERSHIP_SIZE_3_SKU = '3-Person-Family-Membership';
export const FAMILY_MEMBERSHIP_SIZE_4_SKU = '4-Person-Family-Membership';
export const FAMILY_MEMBERSHIP_SIZE_5_SKU = '5-Person-Family-Membership';
export const FAMILY_MEMBERSHIP_SIZE_6_SKU = '6-Person-Family-Membership';

export const FAMILY_MEMBERSHIP_SKUS = [
  FAMILY_MEMBERSHIP_SIZE_2_SKU,
  FAMILY_MEMBERSHIP_SIZE_3_SKU,
  FAMILY_MEMBERSHIP_SIZE_4_SKU,
  FAMILY_MEMBERSHIP_SIZE_5_SKU,
  FAMILY_MEMBERSHIP_SIZE_6_SKU,
];
export const FAMILY_MEMBERSHIP_KEY = 'family-membership';

export const MAX_FAMILY_SIZE = 6;
export const MIN_FAMILY_SIZE = 2;

export const ANNUAL_TAB_INDEX = '1';
