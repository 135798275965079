import { usePathname } from 'next/navigation';
import { useMemo } from 'react';

export const useGetCurrentPage = ():
  | 'Order'
  | 'Accessories'
  | 'Membership'
  | 'Gift Sign In'
  | 'receipt'
  | 'retry'
  | 'password'
  | 'Join'
  | 'Family' => {
  const pathname = usePathname();

  return useMemo(() => {
    if (pathname.includes('/order')) {
      return 'Order';
    } else if (pathname.includes('/accessories')) {
      return 'Accessories';
    } else if (pathname.includes('/membership')) {
      return 'Membership';
    } else if (pathname.includes('/giftsignin')) {
      return 'Gift Sign In';
    } else if (pathname.includes('/receipt')) {
      return 'receipt';
    } else if (pathname.includes('/family')) {
      return 'Family';
    } else if (pathname.includes('/retry')) {
      return 'retry';
    } else if (pathname.includes('/password')) {
      return 'password';
    }
    return 'Join';
  }, [pathname]);
};
