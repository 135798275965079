import type { MembershipProduct, JoinProducts, CartProduct } from 'ui';
import type { Currency, Language, Region } from '@whoop/i18n';
import { i18n } from '@whoop/i18n/lang/client';
import { getMembership, getMembershipTitleAndSubtitle } from 'ui';
import type { CartDisplayItem } from 'ui/types/Cart';
import { formatPriceWithSiteValues } from '@whoop/i18n/utils/priceHelpers';
import type { MembershipProductWithTitleLine2 } from 'types/membershipTypes';
import { getMembershipCartProductInCart } from 'lib/utils/cartManager';
import {
  MAX_FAMILY_SIZE,
  MIN_FAMILY_SIZE,
} from 'ui/utils/constants/familyConstants';

export const getPerPersonPrice = (membership: MembershipProduct): number => {
  const price = membership.display_price;
  const familySize = membership.family_size || 1;
  return price / familySize;
};

export const getDisplayLowestPriceFamilyMembership = (
  memberships: MembershipProduct[],
): MembershipProduct => {
  // have to use the spread operator to avoid mutating the original array (sort is destructive)
  const sortedFamilyMemberships = [...memberships]
    .filter((membership) => membership.membership_type === 'family')
    .sort((a: MembershipProduct, b: MembershipProduct) => {
      const priceA = getPerPersonPrice(a);
      const priceB = getPerPersonPrice(b);
      return priceA - priceB;
    });
  return sortedFamilyMemberships[0];
};

export const getPercentFamilySavings = (
  displayMembership: MembershipProductWithTitleLine2,
  annualMembership: MembershipProductWithTitleLine2,
) => {
  if (!displayMembership || !annualMembership) return 0;
  const annualPerPersonPrice = annualMembership.display_price;
  const displayPerPersonPrice = getPerPersonPrice(displayMembership);
  return Math.round(
    ((annualPerPersonPrice - displayPerPersonPrice) * 100) /
      annualPerPersonPrice,
  );
};

export const sortFamilyMembershipsByFamilySize = (
  familyMemberships: MembershipProduct[],
): MembershipProduct[] => {
  return [...familyMemberships].sort((a, b) => {
    if (a?.family_size && b?.family_size) {
      return a.family_size - b.family_size;
    }
    return 0;
  });
};

interface GetDisplayFamilyMembershipsProps {
  requiredProducts: JoinProducts;
  currency: Currency;
  language: Language;
  region: Region;
}

export const getDisplayFamilyMemberships = ({
  requiredProducts,
  currency,
  language,
  region,
}: GetDisplayFamilyMembershipsProps) => {
  const membershipTitleAndSubtitleOptions = {
    currency,
    language,
    region,
    asAGift: false,
  };
  const requiredFamilyProducts = requiredProducts.family_memberships;
  const unsortedFamilyMemberships = requiredFamilyProducts?.map(
    (familyMembership) =>
      getMembershipTitleAndSubtitle(
        familyMembership,
        membershipTitleAndSubtitleOptions,
      ),
  );
  let familyMemberships: MembershipProductWithTitleLine2[] = [];
  if (unsortedFamilyMemberships) {
    familyMemberships = sortFamilyMembershipsByFamilySize(
      unsortedFamilyMemberships as MembershipProduct[],
    );
  }
  let annualMembership: MembershipProductWithTitleLine2 = getMembership(
    'prepaid',
    requiredProducts.join_memberships,
    12,
  );
  annualMembership =
    annualMembership &&
    getMembershipTitleAndSubtitle(
      annualMembership,
      membershipTitleAndSubtitleOptions,
    );
  return {
    annualMembership,
    familyMemberships,
  };
};

export const getPriceToAddOneFamilyMember = (
  displayMemberships: MembershipProductWithTitleLine2[],
  annualMembership: MembershipProductWithTitleLine2,
) => {
  if (!displayMemberships?.length || !annualMembership) return 0;
  const size2FamilyPlanPrice = displayMemberships.filter(
    (membership) => membership?.family_size === 2,
  )[0]?.display_price;
  return size2FamilyPlanPrice
    ? size2FamilyPlanPrice - annualMembership.display_price
    : 0;
};

export const getPrepaidMembershipFamilyRanges = (
  cartItems: CartDisplayItem[],
) => {
  // Get prepaid membership quantities
  const prepaidMembership = cartItems.find(
    (item) => item.item.item.membership_type === 'prepaid',
  );
  const prepaidMembershipQty = prepaidMembership?.item.quantity || 0;
  const hasOnePrepaidMembershipInCart = prepaidMembershipQty === 1;
  const hasMultipleInRangePrepaidMembershipsInCart =
    prepaidMembershipQty > 1 && prepaidMembershipQty <= MAX_FAMILY_SIZE;
  const hadPrepaidMembershipsInRange =
    hasOnePrepaidMembershipInCart || hasMultipleInRangePrepaidMembershipsInCart;

  return {
    hadPrepaidMembershipsInRange,
    hasOnePrepaidMembershipInCart,
    hasMultipleInRangePrepaidMembershipsInCart,
  };
};

export const getPreferredFamilyPlanUpsellInfo = ({
  cartItems,
  annualMembership,
  familyMemberships,
  isRafGiftFlow,
  asAGift,
  currentPage,
  currency,
  language,
  region,
}: {
  cartItems: CartDisplayItem[];
  annualMembership?: MembershipProduct;
  familyMemberships: MembershipProduct[] | null;
  isRafGiftFlow?: boolean;
  asAGift: boolean;
  currentPage: string;
  currency: Currency;
  language: Language;
  region: Region;
}): {
  displayCTACard: boolean;
  title: string;
  subtitle: string;
} => {
  let title = '',
    subtitle = '';

  // Calculate and format price to add one family member
  const priceToAddOneFamilyMember = getPriceToAddOneFamilyMember(
    familyMemberships as MembershipProductWithTitleLine2[],
    annualMembership as MembershipProductWithTitleLine2,
  );
  const formattedPriceToAddOneFamilyMember = formatPriceWithSiteValues(
    priceToAddOneFamilyMember || 0,
    { currency, language, region },
    { roundUp: true },
  );

  const {
    hadPrepaidMembershipsInRange,
    hasOnePrepaidMembershipInCart,
    hasMultipleInRangePrepaidMembershipsInCart,
  } = getPrepaidMembershipFamilyRanges(cartItems);

  // Determine if the CTA card should be displayed
  const displayCTACard = Boolean(
    familyMemberships &&
      !isRafGiftFlow &&
      !asAGift &&
      currentPage !== 'Family' &&
      hadPrepaidMembershipsInRange,
  );

  // Set and return displayCTACard, title, and subtitle
  if (!displayCTACard) return { displayCTACard, title, subtitle };
  if (hasOnePrepaidMembershipInCart) {
    title = i18n.t('cart:includeYourFamily');
    subtitle = i18n.t('cart:saveBigFamilyMembership', {
      price: formattedPriceToAddOneFamilyMember,
    });
  } else if (hasMultipleInRangePrepaidMembershipsInCart) {
    title = i18n.t('cart:buyingMoreThanOne');
    subtitle = i18n.t('cart:saveBigFamilyMoreThanOne');
  }

  return { displayCTACard, title, subtitle };
};

export const getSelectedMembershipFromTab = (
  selectedTab: string,
  sortedDisplayMemberships: MembershipProductWithTitleLine2[],
): MembershipProductWithTitleLine2 => {
  if (!sortedDisplayMemberships.length) return;
  const index = parseInt(selectedTab, 10) - 1;
  return sortedDisplayMemberships[index];
};

export const getHasFamilyMemberships = (requiredProducts) => {
  return (requiredProducts.family_memberships?.length || 0) > 0;
};

export const getInitialTabSelected = (cartProducts: CartProduct[]): string => {
  const membership = getMembershipCartProductInCart(cartProducts);
  const membershipType = membership?.item.membership_type;
  const defaultTab = MIN_FAMILY_SIZE.toString();
  if (membership) {
    if (membershipType === 'family' && membership.item.family_size) {
      return membership.item.family_size.toString();
    }
    if (membershipType === 'prepaid') {
      if (membership.quantity === 1) {
        return MIN_FAMILY_SIZE.toString();
      } else if (
        membership.quantity >= MIN_FAMILY_SIZE &&
        membership.quantity <= MAX_FAMILY_SIZE
      ) {
        return membership.quantity.toString();
      } else if (membership.quantity > MAX_FAMILY_SIZE) {
        return MAX_FAMILY_SIZE.toString();
      }
    }
  }
  return defaultTab;
};
