/* eslint-disable id-length */
import type { Language } from './internationalization';

export interface LanguagePreference {
  code: string;
  region?: string;
  quality?: number;
}

const EU_SUPPORTED_LANGUAGES: Language[] = ['de', 'es', 'fr', 'it', 'pt'];

/**
 * All sites/countries support English. Some countries support additional languages.
 */
export const ADDITIONAL_LANGUAGES_BY_COUNTRY: Record<
  string,
  Language[] | undefined
> = {
  AT: EU_SUPPORTED_LANGUAGES,
  AX: EU_SUPPORTED_LANGUAGES,
  BE: EU_SUPPORTED_LANGUAGES,
  BG: EU_SUPPORTED_LANGUAGES,
  CA: ['fr'],
  CH: EU_SUPPORTED_LANGUAGES,
  CY: EU_SUPPORTED_LANGUAGES,
  CZ: EU_SUPPORTED_LANGUAGES,
  DE: EU_SUPPORTED_LANGUAGES,
  DK: EU_SUPPORTED_LANGUAGES,
  EE: EU_SUPPORTED_LANGUAGES,
  ES: EU_SUPPORTED_LANGUAGES,
  FI: EU_SUPPORTED_LANGUAGES,
  FO: EU_SUPPORTED_LANGUAGES,
  FR: EU_SUPPORTED_LANGUAGES,
  GL: EU_SUPPORTED_LANGUAGES,
  GR: EU_SUPPORTED_LANGUAGES,
  HR: EU_SUPPORTED_LANGUAGES,
  HU: EU_SUPPORTED_LANGUAGES,
  IE: EU_SUPPORTED_LANGUAGES,
  IT: EU_SUPPORTED_LANGUAGES,
  IS: EU_SUPPORTED_LANGUAGES,
  LT: EU_SUPPORTED_LANGUAGES,
  LU: EU_SUPPORTED_LANGUAGES,
  LV: EU_SUPPORTED_LANGUAGES,
  MC: EU_SUPPORTED_LANGUAGES,
  MT: EU_SUPPORTED_LANGUAGES,
  MX: ['es'],
  NL: EU_SUPPORTED_LANGUAGES,
  NO: EU_SUPPORTED_LANGUAGES,
  PL: EU_SUPPORTED_LANGUAGES,
  PT: EU_SUPPORTED_LANGUAGES,
  RO: EU_SUPPORTED_LANGUAGES,
  SE: EU_SUPPORTED_LANGUAGES,
  SK: EU_SUPPORTED_LANGUAGES,
  SI: EU_SUPPORTED_LANGUAGES,
  US: ['es'],
};

/**
 * All sites/regions support English. Some regions support additional languages.
 */
export const ADDITIONAL_LANGUAGES_BY_REGION: Record<
  string,
  Language[] | undefined
> = {
  CA: ['fr'],
  EU: EU_SUPPORTED_LANGUAGES,
  MX: ['es'],
  US: ['es'],
};

/* eslint-enable id-length */

/**
 * All languages display names on the corresponding language
 */

export const LANGUAGES_DISPLAY_NAMES: Record<Language, string> = {
  en: 'English',
  de: 'Deutsch',
  fr: 'Français',
  es: 'Español',
  it: 'Italiano',
  pt: 'Português',
};
