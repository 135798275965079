import type { SyntheticEvent } from 'react';
import React from 'react';
import { useTranslation } from '@whoop/i18n/lang/client';
import { getCurrentPageFromURL } from 'ui';
import { Button } from 'ui/components/ButtonV2/Button';
import { AmplitudeEvents } from 'whoop-analytics/lib/types';
import { useShallow } from 'zustand/react/shallow';
import KeepShoppingButton from '@/components/cart/keepShoppingButton';
import { useAnalytics } from '@/hooks/analytics';
import { useSiteStore } from '@/store/SiteStoreProvider';
import { getCartMembershipType } from '@/lib/utils/cartManager';
import { useNavigation } from '@/hooks/navigation';

export type CartButtonVariants =
  | 'continueToCheckout'
  | 'control'
  | 'preferCheckout'
  | 'preferAccessories';

export interface CartCTAButtonsProps {
  onCartClose: () => void;
  variant: CartButtonVariants;
}

export function CartCTAButtons({
  onCartClose,
  variant,
}: CartCTAButtonsProps): React.JSX.Element {
  const { t } = useTranslation('cart');
  const { trackAmplitudeEvent } = useAnalytics();
  const { goTo } = useNavigation();
  const { cartProducts } = useSiteStore(
    useShallow((state) => ({
      cartProducts: state.cartProducts,
    })),
  );

  const page = getCurrentPageFromURL();
  const isLandingPage = page === 'Join';
  const isMembershipPage = page === 'Membership';
  const isAccessoriesPage = page === 'Accessories';
  const isFamilyPage = page === 'Family';

  const onContinueToCheckout = (): void => {
    onCartClose();
    trackAmplitudeEvent(AmplitudeEvents.CloseCart, {
      CTA: 'Check Out',
    });
    const searchParams = new URLSearchParams({
      membership: getCartMembershipType(cartProducts) ?? '',
    });
    goTo(`order?${searchParams.toString()}`);
  };

  const onContinueToAccessories = (): void => {
    onCartClose();
    trackAmplitudeEvent(AmplitudeEvents.CloseCart, {
      CTA: variant === 'control' ? 'Continue' : 'Add Accessories',
    });
    goTo('accessories');
  };

  const primaryButtonActionVariants = {
    continueToCheckout: onContinueToCheckout,
    control: onContinueToAccessories,
    preferCheckout: onContinueToCheckout,
    preferAccessories: onContinueToAccessories,
  };

  const secondaryButtonActionVariants: Partial<
    Record<CartButtonVariants, () => void>
  > = {
    preferCheckout: onContinueToAccessories,
    preferAccessories: onContinueToCheckout,
  };

  const clickHandler = (
    event: SyntheticEvent,
    onClickAction?: () => void,
  ): void => {
    event.stopPropagation();
    if (onClickAction) onClickAction();
  };

  const primaryButtonLabelVariants = {
    continueToCheckout: t('continue'),
    control: t('continue'),
    preferCheckout: t('checkout'),
    preferAccessories: t('addAccessories'),
  };

  const { primaryButtonLabel, primaryButtonAction } =
    isLandingPage || isMembershipPage || isFamilyPage
      ? {
          primaryButtonLabel: primaryButtonLabelVariants[variant],
          primaryButtonAction: primaryButtonActionVariants[variant],
        }
      : {
          primaryButtonLabel: t('continueToCheckout'),
          primaryButtonAction: onContinueToCheckout,
        };

  const secondaryButtonLabelVariants: Partial<
    Record<CartButtonVariants, string>
  > = {
    preferCheckout: t('addAccessories'),
    preferAccessories: t('checkout'),
  };

  return (
    <div className='flex flex-col gap-2.5'>
      <Button
        data-testid='cart-continueButton'
        onClick={(event) => clickHandler(event, primaryButtonAction)}
        size='medium'
        type='button'
        variant='bright'
      >
        {primaryButtonLabel}
      </Button>
      {(isLandingPage || isMembershipPage || isFamilyPage) &&
      secondaryButtonLabelVariants[variant] &&
      secondaryButtonActionVariants[variant] ? (
        <Button
          data-testid='cart-secondaryContinueButton'
          onClick={(event) =>
            clickHandler(event, secondaryButtonActionVariants[variant])
          }
          size='medium'
          type='button'
          variant='primary'
        >
          {secondaryButtonLabelVariants[variant]}
        </Button>
      ) : null}
      {isAccessoriesPage ? (
        <KeepShoppingButton
          onClick={(event) => clickHandler(event, onCartClose)}
        >
          {t('keepShopping')}
        </KeepShoppingButton>
      ) : null}
    </div>
  );
}
