import type { PriceFormatOptions } from '@whoop/i18n';
import { useShallow } from 'zustand/react/shallow';
import {
  formatDisplayPrice as formatDisplayPriceHelper,
  formatPrice as formatPriceHelper,
} from 'lib/utils/formatHelpers';
import { useSiteStore } from '@/store/SiteStoreProvider';

export const useFormatter = (): {
  formatDisplayPrice: (
    price: number,
    formatOptions?: PriceFormatOptions,
  ) => string;
  formatPrice: (price: number, formatOptions?: PriceFormatOptions) => string;
} => {
  const { currency, language, region } = useSiteStore(
    useShallow((state) => ({
      currency: state.currency,
      language: state.language,
      region: state.region,
    })),
  );

  const formatDisplayPrice = (
    price: number,
    formatOptions?: PriceFormatOptions,
  ): string => {
    return formatDisplayPriceHelper(
      price,
      { currency, language, region },
      formatOptions,
    );
  };

  const formatPrice = (
    price: number,
    formatOptions?: PriceFormatOptions,
  ): string => {
    return formatPriceHelper(
      price,
      { currency, language, region },
      formatOptions,
    );
  };

  return {
    formatDisplayPrice,
    formatPrice,
  };
};
