import clsx from 'clsx';
import React, { useEffect } from 'react';
import CloseIcon from '../../icons/close';
import { generateIdempotentKey } from '../../utils';
import { UnstyledButton } from '../ButtonV2/Button';
import ArrowLeft from '../../icons/icons/Navigation/ArrowLeft';

export type DrawerPosition = 'right' | 'left' | 'bottom' | 'top';

const getVariantStyles = (
  position: DrawerPosition,
  isOpen: boolean,
): DrawerPositionStyles => {
  switch (position) {
    case 'top':
      return {
        wrapper: 'top-0 left-0 right-0 max-h-96 h-96',
        transition: isOpen ? 'translate-y-0' : '-translate-y-full',
      };
    case 'bottom':
      return {
        wrapper: 'bottom-0 left-0 right-0 max-h-96 h-96',
        transition: isOpen ? 'translate-y-0' : 'translate-y-full',
      };
    case 'left':
      return {
        wrapper: 'left-0 top-0 bottom-0 max-h-full h-full max-w-full',
        transition: isOpen ? 'translate-x-0' : 'translate-x-drawerLeft',
      };
    case 'right':
    default:
      return {
        wrapper: 'right-0 top-0 bottom-0 max-h-full h-full max-w-full',

        transition: isOpen ? 'translate-x-0' : 'translate-x-drawerRight',
      };
  }
};

interface DrawerPositionStyles {
  wrapper: string;
  transition: string;
}

interface IDrawer {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onClose?: () => void;
  children?: React.ReactNode;
  position?: DrawerPosition;
  title?: string;
  blurBackground?: boolean;
  onBackClick?: () => void;
}

function Drawer({
  isOpen,
  setIsOpen,
  onClose,
  children,
  position = 'right',
  title,
  blurBackground = false,
  onBackClick,
  ...props
}: IDrawer): React.ReactNode {
  const variantStyles = getVariantStyles(position, isOpen);

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent): void => {
      if (event.code === 'Escape') {
        if (onClose) {
          onClose();
        }
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.addEventListener('keydown', handleEscapeKey);
      return () => document.removeEventListener('keydown', handleEscapeKey);
    }
    document.body.style.overflow = 'auto';
  }, [isOpen]);

  const handleClose = (): void => {
    if (onClose) {
      onClose();
    }
    setIsOpen(false);
  };

  return (
    <>
      {/* Here we need to disable these rules in order to make a div component clickable (the overlay)
        TODO: investigate if this is achievable by using a before pseudo element on the close button*/}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className={clsx(
          'z-drawerBackdrop fixed bottom-0 left-0 right-0 top-0 overscroll-contain',
          blurBackground ? 'backdrop-blur-sm' : 'bg-black/50',
          isOpen ? 'flex' : 'hidden',
        )}
        data-testid='drawer-close-button'
        onClick={handleClose}
      />
      <div
        className={clsx(
          'z-drawer h-dvh max-h-dvh fixed flex w-full flex-col overflow-x-auto overflow-y-scroll overscroll-contain bg-white px-5 pb-5 pt-5 text-black shadow-2xl transition duration-300 ease-in-out sm:w-auto',
          variantStyles.transition,
          variantStyles.wrapper,
        )}
        role='dialog'
        {...props}
      >
        <div
          className={clsx(
            'flex w-full flex-row items-center pb-6',
            title ? 'justify-between' : 'justify-end',
          )}
        >
          <div className='flex flex-row'>
            {onBackClick ? (
              <UnstyledButton
                className='pr-3'
                data-testid='drawer-back-button'
                onClick={onBackClick}
              >
                <ArrowLeft fill='black' height={24} width={24} />
              </UnstyledButton>
            ) : null}
            <h2 className='text-xl font-semibold uppercase tracking-wider'>
              {title}
            </h2>
          </div>
          <UnstyledButton
            aria-label='close-button'
            className={clsx(title ? 'ml-4' : 'mr-0')}
            onClick={handleClose}
            type='button'
          >
            <CloseIcon height={24} pathStroke='#808080' width={24} />
          </UnstyledButton>
        </div>
        <div className='flex-1'>{children}</div>
      </div>
    </>
  );
}

export default Drawer;
